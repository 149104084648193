import { $host } from "./index";

export const createRentAnalizApi = async (date, rent, free, service, newdog, kassa, vikup, refund) => {
  const { data } = await $host.post("api/rent-analiz", { date, rent, free, service, newdog, kassa, vikup, refund });
  return data;
};

export const getAllRentAnalizApi = async (range) => {
  const data = await $host.get("api/rent-analiz", {
    params: {
      range
    }
  });
  return data;
};

export const deleteRentAnalizApi = async (id) => {
  const data = await $host.delete(`api/rent-analiz/${id}`);
  return data;
};

export const editRentAnalizApi = async (id, date, rent, free, service, newdog, kassa, vikup, refund) => {
  const data = await $host.put(`api/rent-analiz/${id}`, {date, rent, free, service, newdog, kassa, vikup, refund});
  return data;
};
