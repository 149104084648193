import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { ContentTop } from "../UI/ContentTop";
import { ContentFilter } from "../UI/ContentFilter";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { createPaymentsApi, deletePaymentsApi, editPaymentsApi, getAllPaymentsApi } from "../../http/w-company";
import { getAllCompanyApi } from "../../http/companyApi";
import { dateView, managerTableStatus } from "../../utills/functions";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { FilterMatchMode } from "primereact/api";
import { NavLink } from "react-router-dom";

export const CompanyPayments = () => {
    const [loading, setLoading] = useState(true)
    const [visible, setVisible] = useState(false)
    const user = useSelector((state) => state.user.user);
    const [items, setItems] = useState([])
    const [companies, setCompanies] = useState([])
    const toast = useRef(null);
    const [companyName, setCompanyName] = useState("")
    const [companyId, setCompanyId] = useState(null)
    const [fromCompany, setFromCompany] = useState("ООО МИНАКО")
    const [name, setName] = useState("")
    const [status, setStatus] = useState("Не оплачено")
    const [sum, setSum] = useState("")
    const [article, setArticle] = useState("Аренда")
    const [order, setOrder] = useState("")
    const [file, setFile] = useState(null)
    const [statusFilter, setStatusFilter] = useState("Все счета")
    const [articleFilter, setArticleFilter] = useState("Все статьи")
    const [filters, setFilters] = useState({
        companyName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const [companyFilterValue, setCompanyFilterValue] = useState('');

    useEffect(() => {
        getAllPaymentsApi(statusFilter, articleFilter)
        .then(res => {
          setItems(res.data)
        }).catch(e => alert(e)).finally(() => setLoading(false))
  
        getAllCompanyApi("Все компании")
        .then((res) => setCompanies(res.data))
        .catch((e) => alert(e))
        .finally(() => setLoading(false));
    }, [loading, statusFilter, articleFilter])

    useEffect(() => {
        if(companyName) {
            let item = companies.find(f => f.contragent === companyName)
            setCompanyId(item.id)
        } else {
            setCompanyId(null)
        }
    }, [companyName, companies, companyId])

    const onCompanyFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        
        _filters['companyName'].value = value;

        setFilters(_filters);
        setCompanyFilterValue(value);
    };

    const create = () => {
        setVisible(true)
    }

    const headerTable = (i) => {
        return (
            <>
            <ContentTop>
                <div className="content-top-header">
                    <div className="content-top-title">Счета на оплату</div>
                    <div className="content-top-app">
                        <Button onClick={create} label="Добавить" icon="pi pi-plus"/>
                    </div>
                </div>
            </ContentTop>

            <ContentFilter>
                <div className="content-filter-count">Количество: {items.length} шт.</div>
                <div className="content-filter-app">

                    <Dropdown value={statusFilter} onChange={(e) => {
                        setStatusFilter(e.value)
                        setLoading(!loading)
                    }} options={[{name: "Все счета"}, {name: "Оплачено"}, {name: "Не оплачено"}].map(u => u.name)} optionLabel="name" 
                    placeholder="Выбери опцию" className="w-full md:w-14rem" checkmark={true}  highlightOnSelect={false}/>

                    <Dropdown value={articleFilter} onChange={(e) => {
                        setArticleFilter(e.value)
                        setLoading(!loading)
                    }} options={["Все статьи","Аренда", "Продление", "Депозит", "Доставка", "Сервис", "Выездной", "Аксессуары"]} optionLabel="name" 
                    placeholder="Выбери опцию" className="w-full md:w-14rem" checkmark={true}  highlightOnSelect={false}/>

                    <IconField iconPosition="left">
                        <InputIcon className="pi pi-search" />
                        <InputText value={companyFilterValue} onChange={onCompanyFilterChange} placeholder="Поиск по фирме" />
                    </IconField>
                </div>
            </ContentFilter>
            </>
        )
    }

    const allowEdit = (rowData) => {
        return rowData
    };

    const onRowEditComplete = (e) => {
        let _items = [...items];
        let { newData, index } = e;

        _items[index] = newData;

        try {
            editPaymentsApi(newData.id, newData.companyName, newData.companyId, newData.fromCompany, newData.name, newData.status, newData.sum, newData.article, newData.order)
            toast.current.show({severity:'success', summary: 'Успешно!', detail: "Платеж изменен!", life: 3000});
            setLoading(!loading)
        } catch (error) {
           toast.current.show({severity:'error', summary: 'Ошибка', detail: e.response.data.message, life: 3000});
        }
        
    };

    const dateOrder = (i) => {
        return <div>{dateView(i.createdAt)}</div>
    }

    const sumView = (i) => {
        if(i.sum) return <div style={{textAlign:"center", fontWeight: "bold", textDecoration: "underline"}}>{Number(i.sum).toLocaleString("ru")} ₽</div>
    }

    const statusView = (i) => {
        return <div className={`payment-table payment-table-${managerTableStatus(i.status)}`}>{i.status}</div>
    }

    const fileView = (i) => {
        return (
            <NavLink style={{color: "blue", textDecoration: "underline"}} target="_blank" rel="noreferrer" to={`https://lk-rentassistwork.ru/files/${i.file}`}>
                <i className="pi pi-file" style={{fontSize: "1.8rem", color: "black"}}></i>
            </NavLink>
        )
    }

    const statusEditor = (options) => {
        const arr = [{name: "Не оплачено"}, {name: "Оплачено"}, {name: "Отменено"}]
        return (
            <Dropdown
                value={options.value}
                options={arr.map(i => i.name)}
                onChange={(e) => options.editorCallback(e.value)}
                placeholder="Select a Status"
            />
        );
    }

    const articleEditor = (options) => {
        const arr = [{name: "Аренда"}, {name: "Продление"}, {name: "Депозит"}, {name: "Доставка"}, {name: "Сервис"}, {name: "Выездной"}, {name: "Аксессуары"}]
        return (
            <Dropdown
                value={options.value}
                options={arr.map(i => i.name)}
                onChange={(e) => options.editorCallback(e.value)}
                placeholder="Select a Status"
            />
        );
    }

    const fromCompanyEditor = (options) => {
        const arr = [{name: "ООО МИНАКО"}, {name: "ИП Малега В.К."}]
        return (
            <Dropdown
                value={options.value}
                options={arr.map(i => i.name)}
                onChange={(e) => options.editorCallback(e.value)}
                placeholder="Select a Status"
            />
        );
    }

    const orderEditor = (options) => {
        return <InputText style={{fontSize: "14px", width: "100%"}} type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    }

    const sumEditor = (options) => {
        return <InputText style={{fontSize: "14px", width: "100%"}} type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    }

    const commentEditor = (options) => {
        return <InputTextarea style={{width: "100%"}} value={options.value} onChange={e => options.editorCallback(e.target.value)}/>
    }

    const createItemButton = () => {
        if(!companyId || !companyName || !file) {
            toast.current.show({severity:'error', summary: 'Ошибка', detail: "Выбери компанию и файл", life: 3000});
            return
        } else {
            try {
                const formData = new FormData()
                formData.append("companyName", companyName)
                formData.append("companyId", companyId)
                formData.append("fromCompany", fromCompany)
                formData.append("name", name)
                formData.append("status", status)
                formData.append("sum", sum)
                formData.append("article", article)
                formData.append("order", order)
                formData.append("file", file)
                
                createPaymentsApi(formData)
                toast.current.show({severity:'success', summary: 'Успешно!', detail: "Платеж создан!", life: 3000});
              } catch (error) {
                  alert(error)            
              }
        }
  
          setCompanyName("")
          setCompanyId(null)
          setFromCompany("ООО МИНАКО")
          setName("")
          setStatus("Не оплачено")
          setSum("")
          setArticle("Аренда")
          setOrder("")
          setFile(null)
          setLoading(!loading)
          setVisible(false)
    }

    const deleteTable = (i) => {
        return <i onClick={() => {
            const accept = () => {
                try {
                    deletePaymentsApi(i.id)
                  setLoading(!loading)
                } catch (error) {
                  alert(error)
                }
              };
          
              const reject = () => {
                  return
              };
      
              confirmDialog({
                message: 'Вы действительно хотите удалить заказ?',
                header: 'Удаление',
                icon: 'pi pi-info-circle',
                defaultFocus: 'reject',
                acceptClassName: 'p-button-danger',
                position: 'center',
                accept,
                reject
            });
              
        }} className="pi pi-trash" style={{ fontSize: '1.3rem' }}></i>
    }

    return (
        <div className="field">
            <Toast ref={toast} position="bottom-center"/>
            <ConfirmDialog />
            <DataTable filters={filters} editMode="row" dataKey="id" onRowEditComplete={onRowEditComplete} value={items} header={headerTable} showGridlines stripedRows selectionMode="single" scrollable  paginator rows={25} rowsPerPageOptions={[25, 50, 100]} tableStyle={{ minWidth: '45rem' }} emptyMessage="Пусто...">
                <Column body={dateOrder} header="Дата" field="createdAt" style={{width: "13%"}}/>
                <Column header="Фирма" field="companyName" style={{width: "15%"}}/>
                <Column editor={fromCompanyEditor} header="Контрагент" field="fromCompany" style={{width: "9%", textAlign: "center"}}/>
                <Column editor={orderEditor} header="Заказ" field="order" style={{width: "5%", textAlign: "center"}}/>
                <Column editor={sumEditor} body={sumView} header="Сумма" field="sum" style={{width: "6%", textAlign: "center"}}/>
                <Column editor={commentEditor} header="Комментарий" field="name"/>
                <Column editor={statusEditor} body={statusView} header="Статус" field="status" style={{width: "10%"}}/>
                <Column editor={articleEditor} header="Статья" field="article" style={{width: "10%", textAlign: "center"}}/>
                <Column body={fileView} header="Файл" field="file" style={{width: "2%", textAlign: "center"}}/>
                <Column rowEditor={allowEdit} headerStyle={{ width: '3%', minWidth: '7rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
                {user.role === "ADMIN" && <Column body={deleteTable} header="" bodyStyle={{ textAlign: 'center' }} style={{ width: '3%', color: 'red' }}></Column>}
            </DataTable>

            <Dialog style={{ width: '32rem', fontSize: "1.4rem"}} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={null} visible={visible} onHide={() => {if (!visible) return; setVisible(false); }}>
                <div className="create">
                    <div className="create-row">
                        <label htmlFor="role" className="font-bold">
                            Компания
                        </label>
                        <Dropdown
                            value={companyName}
                            options={companies.filter(i => i.status === "Действующий" || i.status === "Новый" || i.status === "Переговоры").map(i => i.contragent)}
                            onChange={(e) => setCompanyName(e.target.value)}
                            placeholder="Select a Company"
                            style={{width: "100%"}}
                        />
                    </div>

                    <div className="create-row">
                        <label htmlFor="role" className="font-bold">
                            Контрагент
                        </label>
                        <Dropdown
                            value={fromCompany}
                            options={["ООО МИНАКО", "ИП Малега В.К."]}
                            onChange={(e) => setFromCompany(e.target.value)}
                            placeholder="Select a Company"
                            style={{width: "100%"}}
                        />
                    </div>

                    <div className="create-row">
                        <label htmlFor="position" className="font-bold">
                            Заказ
                        </label>
                        <InputText value={order} onChange={e => setOrder(e.target.value)} id="position"></InputText>
                    </div>

                    <div className="create-row">
                        <label htmlFor="comment" className="font-bold">
                            Сумма
                        </label>
                        <InputText value={sum} onChange={e => setSum(e.target.value)} id="position"></InputText>
                    </div>

                    <div className="create-row">
                        <label htmlFor="comment" className="font-bold">
                            Номер счёта
                        </label>
                        <InputText value={name} onChange={e => setName(e.target.value)} id="position"></InputText>
                    </div>  

                    <div className="create-row">
                        <label htmlFor="role" className="font-bold">
                            Статья
                        </label>
                        <Dropdown
                            value={article}
                            options={["Аренда", "Продление", "Депозит", "Доставка", "Сервис", "Выездной", "Аксессуары"]}
                            onChange={(e) => setArticle(e.target.value)}
                            placeholder="Select a Status"
                            style={{width: "100%"}}
                        />
                    </div>

                    <div className="create-row">
                        <label htmlFor="role" className="font-bold">
                            Статус
                        </label>
                        <Dropdown
                            value={status}
                            options={["Не оплачено", "Оплачено", "Отменено"]}
                            onChange={(e) => setStatus(e.target.value)}
                            placeholder="Select a Status"
                            style={{width: "100%"}}
                        />
                    </div>

                    <div className="create-row">
                        <label htmlFor="role" className="font-bold">
                            Файл
                        </label>
                        <input type="file" onChange={e => setFile(e.target.files[0] || null)}/>
                    </div>

                    <Button onClick={createItemButton}>Добавить</Button>
                </div>
            </Dialog>
        </div>
    )
}