import { $host } from "./index";

export const createCarMasterApi = async (managerId, masterId, order, date, company, adress, phone, vin, comment, priority, status) => {
    const { data } = await $host.post("api/car-master", { managerId, masterId, order, date, company, adress, phone, vin, comment, priority, status });
    return data;
  };

export const getAllCarMasterApi = async (range, date, status) => {
    const data = await $host.get("api/car-master", {
        params: {
          range, date, status
        }
      });
    return data;
};
  
export const getOneCarMasterApi = async (id) => {
    const data = await $host.get(`api/car-master/${id}`);
    return data;
};
  
export const deleteCarMasterApi = async (id) => {
    const data = await $host.delete(`api/car-master/${id}`);
    return data;
};
  
export const editCarMasterApi = async (id, masterId, order, date, company, adress, phone, vin, comment, priority, status) => {
    const data = await $host.put(`api/car-master/${id}`, {masterId, order, date, company, adress, phone, vin, comment, priority, status});
    return data;
};

export const getAllToMasterApi = async (date, masterId) => {
  const data = await $host.get("api/car-master/master", {
    params: {
      date, masterId
    }
  });
  return data;
};

export const statusCarMasterApi = async (id, status) => {
  const data = await $host.patch(`api/car-master/${id}`, {status});
  return data;
};
