import React, { useEffect, useState } from "react";
import "./index.scss";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";

export const DateNow = () => {
  const [dateState, setDateState] = useState(new Date());
  const [visible, setVisible] = useState(false)
  const [sum, setSum] = useState(null)
  const [payDays, setPayDays] = useState(null)
  const [days, setDays] = useState(null)
  const [comp, setComp] = useState(20)
  const [refund, setRefund] = useState(null)
  
  useEffect(() => {
    setInterval(() => setDateState(new Date()), 30000);
  }, []);

  const calc = () => {
    let total = ((sum/payDays)*days)-((sum/payDays)*days)*(comp/100)
    setRefund(Math.floor(total))
  }

  const clear = () => {
    setComp(20)
    setDays(0)
    setPayDays(0)
    setSum(0)
    setRefund(0)
  }

  return (
    <div className="date">
      <p>
        {" "}
        {dateState.toLocaleDateString("ru", {
          day: "numeric",
          month: "short",
          year: "numeric",
        })}
      </p>
      <p>
        {dateState.toLocaleString("ru", {
          hour: "numeric",
          minute: "numeric",
          hour12: false,
        })}
      </p>

      <Button onClick={() => setVisible(!visible)} severity="secondary" icon="pi pi-calculator"/>

      <Dialog style={{ width: '32rem', fontSize: "1.4rem"}} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={"Калькулятор возвратов"} visible={visible} onHide={() => {if (!visible) return; setVisible(false); }}>
        <div style={{display: "flex", flexDirection: "column", gap: "1.5rem"}}>
        <div className="create-row">
            <label htmlFor="order" className="font-bold">
              Оплаченная сумма
            </label>
            <InputText value={sum} onChange={(e) => setSum(+e.target.value)} id="order"></InputText>
        </div>
        <div className="create-row">
            <label htmlFor="order" className="font-bold">
              Количество оплаченных дней
            </label>
            <InputText value={payDays} onChange={(e) => setPayDays(+e.target.value)} id="order"></InputText>
        </div>
        <div className="create-row">
            <label htmlFor="order" className="font-bold">
              Количество оставшихся дней
            </label>
            <InputText value={days} onChange={(e) => setDays(+e.target.value)} id="order"></InputText>
        </div>
        <div className="create-row">
            <label htmlFor="order" className="font-bold">
              Компенсация (%)
            </label>
            <InputText value={comp} onChange={(e) => setComp(+e.target.value)} id="order"></InputText>
        </div>

        <Button onClick={calc} style={{fontSize: "1.5rem"}}>Посчитать</Button>
        <Button onClick={clear} style={{fontSize: "1.5rem"}}>Очистить</Button>

        <div style={{marginTop: "1rem"}}>
          ИТОГО К ВОЗВРАТУ: <span style={{fontSize: "2rem", fontWeight: "bold", textDecoration: "underline", color: "red"}}>{refund} ₽</span>
        </div>
        </div>
      </Dialog>
    </div>
  );
};
