import React, { useState } from "react";
import './index.scss'
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import { PanelMenu } from "primereact/panelmenu";
import { linkAdmin, linkManager } from "../../../utills/links";
import { sources } from "../../../utills/menus";
import { useDispatch, useSelector } from "react-redux";
import { getAuth, getUser } from "../../../store/reducers/user";

export const ContentTop = ({children}) => {
    const [burger, setBurger] = useState(false)
    const user = useSelector(state => state.user.user)
    const dispatch = useDispatch()
    const exit = () => {
        dispatch(getAuth(false))
        dispatch(getUser(null))
        localStorage.clear("token")
    }

    const links = () => {
        if(user.role === "ADMIN") {
           return linkAdmin(user.position).filter(i => {
            if(i) return i})
        } else {
           return linkManager()
        }
    }

    return (
        <div className="content-top">
           {user.role !== "CARMASTER" && <Button onClick={e => setBurger(true)} className="burger-button" style={{marginRight: "1rem", background: "#4b4b4b", border: "none"}} icon={"pi pi-align-justify"}></Button>}
           {children}

           <Sidebar visible={burger} onHide={() => setBurger(false)}>
            <div className="sidebar-burger">
                    <div className="sidebar-links">
                        <PanelMenu model={links()} style={{fontSize: "1.6rem"}} className="ww" />
                    </div>

                    <div className="sidebar-sites">
                        <div className="card flex justify-content-center">
                            <PanelMenu model={sources} className="w-full md:w-20rem" />
                        </div>
                    </div>

                    <div className="sidebar-burger-profile">
                        <span> <i className="pi pi-user" style={{marginRight: "5px"}}></i> {user.name}</span>
                        <Button onClick={exit} icon="pi pi-sign-out" label="Выйти" />
                    </div>
            </div>
           </Sidebar>
        </div>
    )
}