import { CarMaster } from "../components/CarMaster"
import { AccauntCarMaster } from "../components/CarMaster/accaunt"
import { Comments } from "../components/Comments"
import { Company } from "../components/Company"
import { Ideas } from "../components/Ideas"
import { Information } from "../components/Information"
import { ManagerTables } from "../components/ManagerTables"
import { Refunds } from "../components/Refunds"
import { Reports } from "../components/Reports"
import { Settings } from "../components/Settings"
import { Task } from "../components/Task"
import { WCompany } from "../components/W-Company"

export const ManagerRoutes = [
    {id: 1, path: "/task", element: <Task/>},
    {id: 3, path: "/refunds", element: <Refunds/>},
    {id: 4, path: "/comments-client", element: <Comments/>},
    {id: 5, path: "/manager-tables/*", element: <ManagerTables/>},
    {id: 6, path: "/company/*", element: <Company/>},
    {id: 7, path: "/ideas", element: <Ideas/>},
    {id: 8, path: "/information/*", element: <Information/>},
    {id: 9, path: "/w-company/*", element: <WCompany/>},
    {id: 10, path: "/car-master", element: <CarMaster/>}
]

export const AdminRoutes = [
    {id: 1, path: "/task", element: <Task/>},
    {id: 2, path: "/settings", element: <Settings/>},
    {id: 4, path: "/refunds", element: <Refunds/>},
    {id: 5, path: "/company/*", element: <Company/>},
    {id: 6, path: "/comments-client", element: <Comments/>},
    {id: 7, path: "/manager-tables/*", element: <ManagerTables/>},
    {id: 8, path: "/ideas", element: <Ideas/>},
    {id: 9, path: "/information/*", element: <Information/>},
    {id: 10, path: "/w-company/*", element: <WCompany/>},
    {id: 11, path: "/report/*", element: <Reports/>},
    {id: 12, path: "/car-master", element: <CarMaster/>}
]

export const CarMasterRoutes = [
    {id: 1, path: "/orders", element: <AccauntCarMaster/>}
]