import React, { useEffect, useState } from "react";
import './index.scss'
import { DataView } from "primereact/dataview";
import { useDispatch, useSelector } from "react-redux";
import { Calendar } from "primereact/calendar";
import { ContentTop } from "../UI/ContentTop";
import { Button } from "primereact/button";
import { ContentFilter } from "../UI/ContentFilter";
import { getAuth, getUser } from "../../store/reducers/user";
import { getAllToMasterApi, statusCarMasterApi } from "../../http/carMaster";
import { masterCardStyle } from "../../utills/functions";
import { getAllTaskPriority } from "../../http/settings";

export const AccauntCarMaster = () => {
    const [loading, setLoading] = useState(false)
    const user = useSelector((state) => state.user.user);
    const dispatch = useDispatch()
    const [items, setItems] = useState([])
    const [priorities, setPriorities] = useState(null)
    const [date, setDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()))

    useEffect(() => {
        getAllToMasterApi(date, user.id)
        .then(res => setItems(res.data))
        .catch(e => alert(e))
        .finally(() => setLoading(false))

        getAllTaskPriority()
        .then(res => setPriorities(res.data))
        .catch(e => alert(e))
        .finally(() => setLoading(false))
    }, [loading, date, user])

    const exit = () => {
        dispatch(getAuth(false))
        dispatch(getUser(null))
        localStorage.clear("token")
    }

    const headerTable = (i) => {
        return (
            <>
            <ContentTop>
                <div className="content-top-header">
                    <div className="content-top-title">Заявки</div>
                    <div className="content-top-app">
                        <Button onClick={exit} icon="pi pi-sign-out" label="Выйти" />
                    </div>
                </div>
            </ContentTop>

            <ContentFilter>
                <div className="content-filter-count">Количество: {items.length} шт.</div>

                <div className="content-filter-app">
                    <Calendar showButtonBar value={date} onChange={(e) => setDate(e.value)} dateFormat="dd/mm/yy" readOnlyInput hideOnRangeSelection style={{width: "11rem"}}/>
                </div>
            </ContentFilter>
            </>
        )
    }

    const listTemplate = (items) => {
        if (!items || items.length === 0) return null;

        let list = items.map((product, index) => {
            return itemTemplate(product, index);
        });

        return <div className="grid grid-nogutter">{list}</div>;
    }

    const pickColor = (color) => {
        let item = priorities?.filter(i => i.name === color)
        if(item) return item[0]?.color 
    }

    const itemTemplate = (i, index) => {
        return (
            <div className={`card card-${masterCardStyle(i.status)}`}>
                <div className="card-info">
                    <div className="card-priority"><span style={{fontWeight: "700"}}>Важность:</span> <span style={{fontWeight: "bold", textDecoration: "underline", background: `#${pickColor(i.priority)}`, color: "white", padding: "0.3rem", borderRadius: "0.4rem"}}>{i.priority}</span></div>
                    <div className="card-adress">{i.adress}</div>
                    <div className="card-phone">{i.phone}</div>
                    <div className="card-vin">{i.vin}</div>
                    <div className="card-comment">{i.comment}</div>
                </div>
                {i.status === "Новый" && <div className="card-btns">
                    <Button onClick={() => {statusCarMasterApi(i.id, "Выполнено"); setLoading(!loading)}} style={{background: "green", border: "none"}}>Выполнено</Button>
                    <Button onClick={() => {statusCarMasterApi(i.id, "В сервис"); setLoading(!loading)}}>Забрать в сервис</Button>
                </div>}

                {i.status !== "Новый" && <div className="card-btns">
                    <Button onClick={() => {statusCarMasterApi(i.id, "Новый"); setLoading(!loading)}} style={{background: "red", border: "none"}}>Отменить</Button>
                </div>}
            </div>
        )
    }

    return (
        <div className="field">
            <DataView value={items} listTemplate={listTemplate} header={headerTable()} paginator rows={5}/>
        </div>
    )
}