export const getRange = (array) => {
    let start 
    let end 
    if(array[0]) {
      start = new Date(array[0] - array[0].getTimezoneOffset() * 60000).toISOString() || "" 
    }
    if(array[1]) {
      end =  new Date(array[1] - array[1].getTimezoneOffset() * 539999).toISOString() || ""
    }
    let range = [start, end]
    return range
}

export const dateView = (time) => {
    return new Date(time).toLocaleDateString("ru", {
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: false,
})
}

export const companyCardStatus = (status) => {
  if(status === "Новый") return "new"
  if(status === "Действующий") return "processed"
  if(status === "Переговоры") return "conversationed"
  if(status === "Завершенный") return "ended"
  if(status === "Отмененный") return "canceled"
}

export const paymentStatus = (status) => {
  if(status === "Оплачено") return "paid"
  if(status === "Не оплачено") return "notpaid"
  if(status === "Отменено") return "canceled"
}

export const managerTableStatus = (status) => {
  if(status === "Новый" || status === "Ожидает оплаты" || status === "Отменено") return "new"
  if(status === "Выполнен" || status === "Выполнено" || status === "Оплачено") return "done"
  if(status === "Отменен" || status === "Не выполнено" || status === "Не выполнен" || status === "Не оплачено") return "canceled"
}

export const carMasterStatus = (status) => {
  if(status === "Новый") return 'new'
  if(status === "Выполнено") return 'done'
  if(status === "В сервис") return 'service'
  if(status === "Перенос") return 'trans'
  if(status === "Отменено") return 'cancel'
}

export const masterCardStyle = (status) => {
  if(status === "Новый") return 'new'
  if(status === "Выполнено") return 'done'
  if(status === "В сервис") return 'service'
  if(status === "Перенос") return 'trans'
  if(status === "Отменено") return 'cancel'
}