import React, { useEffect,  useRef,  useState } from "react";
import './index.scss'
import { DataView } from "primereact/dataview";
import { ContentTop } from "../UI/ContentTop";
import { Button } from "primereact/button";
import { ContentFilter } from "../UI/ContentFilter";
import { useSelector } from "react-redux";
import { createCompanyApi, getAllCompanyApi } from "../../http/companyApi";
import { companyCardStatus } from "../../utills/functions";
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Tooltip } from "primereact/tooltip";
import { useNavigate } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";

export const CompanyCards = () => {
    const [loading, setLoading] = useState(true)
    const user = useSelector((state) => state.user.user);
    const [items, setItems] = useState([])
    const [visible, setVisible] = useState(false);
    const toast = useRef(null);
    const [company, setCompany] = useState(null) 
    const [contragent, setContragent] = useState(null)
    const [comment, setComment] = useState(null) 
    const [format, setFormat] = useState({name: "Аренда"}) 
    const [statusFilter, setStatusFilter] = useState("Действующий")
    const navigate = useNavigate()


    useEffect(() => {
        if(loading) getAllCompanyApi(statusFilter)
        .then(res => setItems(res.data))
        .catch(e => alert(e))
        .finally(() => setLoading(false))
    }, [loading,statusFilter])

    const goToCompany = (item) => {
        navigate(`/company/${item.id}`, {state: {item}})
    }

    const headerCompany = () => {
        return (
            <>
            <ContentTop>
                <div className="content-top-header">
                    <div className="content-top-title">Компании</div>
                    <div className="content-top-app">
                        <Button onClick={() => setVisible(true)} label="Добавить" icon="pi pi-plus"/>
                    </div>
                </div>
            </ContentTop>

            <ContentFilter>
                <div className="content-filter-count">Количество: {user.role === "ADMIN" ? items.length : items.filter(i => i.availability !== "Руководителю").length} шт.</div>

                <div className="content-filter-app">
                    <Dropdown value={statusFilter} onChange={(e) => {
                        setStatusFilter(e.value)
                        setLoading(!loading)
                    }} options={[{name: "Все компании"}, {name: "Новый"}, {name: "Действующий"}, {name: "Переговоры"}, {name: "Завершенный"}, {name: "Отмененный"}].map(u => u.name)} optionLabel="name" 
                    placeholder="Выбери опцию" className="w-full md:w-14rem" checkmark={true}  highlightOnSelect={false}/>
                    {user.role === "ADMIN" && (
                        <>
                        <Button icon="pi pi-file-excel" style={{background: "green", height: "26px"}}/>
                        <Button icon="pi pi-chart-line" style={{height: "26px"}}/>
                        </>
                    )}
                </div>
            </ContentFilter>
            </>
        )
    }

    const itemTemplate = (i, index) => {
        // let sumNotPaid = i.payments.filter(e => e.status === "Не оплачено").reduce((a,b) => {
        //     return a + b.sum
        // }, 0)

        return (
            <div className="dcard">
                <div className="dcard-header">
                    <div className="dcard-header-title">
                        <h3 onClick={() => goToCompany(i)}>{i.contragent}</h3>
                        <div style={{display:"flex", alignItems: "center", gap: "1rem"}}>
                            <div className="dcard-header-title-format">{i.format}</div>
                            <div className={`dcard-header-title-status dcard-header-title-status-${companyCardStatus(i.status)}`}>{i.status}</div>
                        </div>
                    </div>
                    <div className="dcard-header-subtitle">
                        <div className="dcard-header-subtitle-name">{i.company}</div>
                        <div className="dcard-header-subtitle-dogovor">#{i.numberDogovor}</div>
                        {/* {i.payments.filter(e => e.status === "Не оплачено").length > 0 && <i data-pr-tooltip={`Не оплаченые счета (${i.payments.filter(e => e.status === "Не оплачено").length} шт.) на сумму ${sumNotPaid.toLocaleString()} ₽`} className="pi pi-exclamation-triangle" style={{ fontSize: '1.8rem', color: "red" }}></i>} */}
                        {/* <Tooltip target=".pi-exclamation-triangle" mouseTrack mouseTrackLeft={-5} mouseTrackTop={15} position="bottom" /> */}
                    </div>
                </div>
                <div className="dcard-body">
                    <Accordion >
                        <AccordionTab style={{fontSize: "14px"}} header="Отвественные">
                            {i.staff.map(i => {
                                return (
                                    <div className="dcard-body-staff">
                                        <div className="dcard-body-staff-name">{i.name}</div>
                                        <div className="dcard-body-staff-phone">{i.phone}</div>
                                    </div>
                                )
                            })}
                        </AccordionTab>
                        <AccordionTab style={{fontSize: "14px"}} header="Адреса">
                            {i.adress.map(i => {
                                return (
                                    <div className="dcard-body-adress">
                                        <div className="dcard-body-adress-name">{i.name}</div>
                                    </div>
                                )
                            })}
                        </AccordionTab>
                        <AccordionTab style={{fontSize: "14px"}} header="Города">
                            {i.city.map(i => {
                                return (
                                    <div className="dcard-body-city">
                                        <div className="dcard-body-city-name">{i.name}</div>
                                    </div>
                                )
                            })}
                        </AccordionTab>
                        <AccordionTab style={{fontSize: "14px"}} header="Комментарий/Описание">
                            <div className="dcard-body-comment">{i.comment}</div>
                        </AccordionTab>
                    </Accordion>
                </div>
                <div className="dcard-footer">
                    <div className="dcard-footer-delivery">
                        <span>Доставка: </span>
                        <span style={{textDecoration: "underline"}}>{i.costDelivery} ₽</span>
                    </div>
                    <div className="dcard-footer-master">
                        <span>Мастер: </span>
                        <span style={{textDecoration: "underline"}}>{i.costMaster} ₽</span>
                        
                    </div>
                </div>
            </div>
        )
    }

    const listTemplate = (items) => {
        if (!items || items.length === 0) return null;

        let list = items.map((product, index) => {
            return itemTemplate(product, index);
        });

        return <div className="grid grid-nogutter">{list}</div>;
    };

    if(loading) {
        return <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8" animationDuration="2s" />
    }

    const createCompany = () => {
        if(!company || !contragent) {
            toast.current.show({severity:'error', summary: 'Ошибка', detail: "Заполни название компании и контрагента", life: 3000});
            return
        }

        try {
          createCompanyApi(company, contragent, "Переговоры", comment, format.name, null, null, null, null, null, null)
          setVisible(!visible)
          setLoading(!loading)
        } catch (error) {
          alert(error)      
        }
      }

    return (
        <div className="field">
            <Toast ref={toast} position="bottom-center"/>
            <Dialog header="Добавить новую компанию" visible={visible} style={{ width: '50vw', fontSize: "1.4rem" }} onHide={() => {if (!visible) return; setVisible(false); }}>
                <div className="create-company">
                    <div className="company-row">
                        <h3 className="company-row-title">Компания</h3>
                        <InputText value={company} onChange={(e) => setCompany(e.target.value)} />
                    </div>
                    <div className="company-row">
                        <h3 className="company-row-title">Контрагент</h3>
                        <InputText value={contragent} onChange={(e) => setContragent(e.target.value)} />
                    </div>
                    <div className="company-row">
                        <h3 className="company-row-title">Формат</h3>
                        <Dropdown value={format} onChange={(e) => setFormat(e.value)} options={[{name: "Аренда"}, {name: "Депозит"}, {name: "Промокод"}]} optionLabel="name" 
                            placeholder="Select a Format" />
                    </div>
                    <div className="company-row">
                        <h3 className="company-row-title">Комментарий</h3>
                        <InputTextarea value={comment} onChange={(e) => setComment(e.target.value)} />
                    </div>

                    <Button onClick={createCompany} style={{width: "12rem"}}>Добавить</Button>
                </div>
            </Dialog>
            <DataView value={items} header={headerCompany()} listTemplate={listTemplate} paginator rows={24} emptyMessage="Пусто..."/>
        </div>
    )
}