import React, { useEffect, useRef, useState } from "react";
import './index.scss'
import { ContentTop } from "../UI/ContentTop";
import { ContentFilter } from "../UI/ContentFilter";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import generatePDF from "react-to-pdf";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { Column } from "primereact/column";
import { createRentAnalizApi, editRentAnalizApi, getAllRentAnalizApi } from "../../http/rentAnaliz";
import { getRange } from "../../utills/functions";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";

export const ReportInfo = () => {
    const [loading, setLoading] = useState(true)
    const [items, setItems] = useState([])
    const [dates, setDates] = useState([new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)]);
    const targetRef = useRef();
    const toast = useRef(null);
    const [visible, setVisible] = useState(false)
    const [date, setDate] = useState(null)

    useEffect(() => {
        getAllRentAnalizApi(getRange(dates))
        .then(res => setItems(res.data))
        .catch(e => alert(e))
        .finally(() => setLoading(false))
    }, [loading, dates])

    const headerTable = (i) => {
        return (
            <>
            
            </>
        )
    }

    const createItem = () => {
        try {
            if(!date) return
            createRentAnalizApi(new Date(date.setHours(date.getHours() + 3)).toISOString(), null, null, null, null, null, null, null)
            toast.current.show({severity:'success', summary: 'Успешно!', detail: "Запись добавлена!", life: 3000});

            setVisible(false)
            setLoading(!loading)
            setDate(null)
        } catch (error) {
            toast.current.show({severity:'error', summary: 'Ошибка', detail: error, life: 3000});
        }
    }

    const allowEdit = (rowData) => {
        return rowData
    };

    const onRowEditComplete = (e) => {
        let _items = [...items];
        let { newData, index } = e;

        _items[index] = newData;

        try {
            setItems(_items);
            editRentAnalizApi(newData.id, newData.date, newData.rent, newData.free, newData.service, newData.newdog, newData.kassa, newData.vikup, newData.refund)
            toast.current.show({severity:'success', summary: 'Успешно!', detail: "Запись изменена!", life: 3000});
            setLoading(!loading)
        } catch (error) {
            toast.current.show({severity:'error', summary: 'Ошибка', detail: e.response.data.message, life: 3000});
        }
    };

    const dateView = (i) => {
        return <div>{!i.date ? "" : new Date(i.date).toLocaleDateString("ru", {
            day: "numeric",
            month: "long",
            year: "numeric",
            })}</div>
    }

    const rentEditor = (options) => {
        return <InputText style={{fontSize: "14px", width: "100%"}} type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    }

    const freeEditor = (options) => {
        return <InputText style={{fontSize: "14px", width: "100%"}} type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    }

    const serviceEditor = (options) => {
        return <InputText style={{fontSize: "14px", width: "100%"}} type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    }

    const newDogEditor = (options) => {
        return <InputText style={{fontSize: "14px", width: "100%"}} type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    }

    const kassaEditor = (options) => {
        return <InputText style={{fontSize: "14px", width: "100%"}} type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    }

    const vikupEditor = (options) => {
        return <InputText style={{fontSize: "14px", width: "100%"}} type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    }

    const refundEditor = (options) => {
        return <InputText style={{fontSize: "14px", width: "100%"}} type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    }

    const kassaView = (i) => {
        if(i.kassa) return <div style={{textAlign:"center", fontWeight: "bold", textDecoration: "underline"}}>{Number(i.kassa).toLocaleString("ru")} ₽</div>
    }

    const vikupView = (i) => {
        if(i.vikup) return <div style={{textAlign:"center", fontWeight: "bold", textDecoration: "underline"}}>{Number(i.vikup).toLocaleString("ru")} ₽</div>
    }

    const refundView = (i) => {
        if(i.refund) return <div style={{textAlign:"center", fontWeight: "bold", textDecoration: "underline"}}>{Number(i.refund).toLocaleString("ru")} ₽</div>
    }

    const kassaTotal = () => {
        let kassa = 0;

        for (let item of items) {
            kassa += +item.kassa;
        }
        
        return Number(kassa).toLocaleString("ru") + " ₽";
    };

    const vikupTotal = () => {
        let vikup = 0;

        for (let item of items) {
            vikup += +item.vikup;
        }
        
        return Number(vikup).toLocaleString("ru") + " ₽";
    };
    
    const refundTotal = () => {
        let refund = 0;

        for (let item of items) {
            refund += +item.refund;
        }
        
        return Number(refund).toLocaleString("ru") + " ₽";
    }

    const Total = () => {
        let refund = 0;
        let vikup = 0;
        let kassa = 0;

        for (let item of items) {
            refund += +item.refund;
            kassa += +item.kassa;
            vikup += +item.vikup;
        }

        let total = Number(kassa) + Number(vikup) - Number(refund)
        
        return Number(total).toLocaleString("ru") + " ₽";
    };


    const footerGroup = (
        <ColumnGroup>
            <Row>
                <Column colSpan={4} footerStyle={{ textAlign: 'right' }} />

                <Column footer={null} footerStyle={{ textAlign: 'center' }}/>
                <Column footer={kassaTotal} footerStyle={{ textAlign: 'center', color: "green" }}/>
                <Column footer={vikupTotal} footerStyle={{ textAlign: 'center', color: "green"  }}/>
                <Column footer={refundTotal} footerStyle={{ textAlign: 'center', color: "red"  }}/>
                <Column footer={null} footerStyle={{ textAlign: 'center' }}/>
            </Row>
            <Row>
                <Column colSpan={8} footer={Total} footerStyle={{ textAlign: 'right', color: "green", fontSize: "19px", textDecoration: "underline" }} />
            </Row>
        </ColumnGroup>
    );

    return (
        <div className="field">
            <ContentTop>
                <div className="content-top-header">
                    <div className="content-top-title">Информация по технике и рознице</div>
                    <div className="content-top-app">
                        <Button onClick={() => setVisible(!visible)} label="Добавить" icon="pi pi-plus"/>
                    </div>
                </div>
            </ContentTop>

            <ContentFilter>
                <div className="content-filter-app">
                    <Calendar value={dates} onChange={(e) => setDates(e.value)} selectionMode="range" dateFormat="dd/mm/yy" readOnlyInput hideOnRangeSelection />
                    <Button type="button" icon="pi pi-file-pdf" severity="warning" onClick={() => generatePDF(targetRef, {filename: 'payments.pdf'})} data-pr-tooltip="PDF" />
                </div>
            </ContentFilter>

            <Toast ref={toast} position="bottom-center"/>
            <div ref={targetRef} className="tabless">
                <DataTable footerColumnGroup={footerGroup} editMode="row" onRowEditComplete={onRowEditComplete} dataKey="id" value={items}  showGridlines stripedRows selectionMode="single" scrollable  tableStyle={{ minWidth: '100%' }}>
                    <Column body={dateView} header={"Дата"} field="date" style={{width: "18%"}}/>
                    <Column editor={rentEditor} header={"В аренде"} field="rent" style={{width: "11%", textAlign: "center"}}/>
                    <Column editor={freeEditor} header={"Свободных"} field="free" style={{width: "11%", textAlign: "center"}}/>
                    <Column editor={serviceEditor} header={"В сервисе"} field="service" style={{width: "11%", textAlign: "center"}}/>
                    <Column editor={newDogEditor} header={"Новых договоров"} field="newdog" style={{width: "11%", textAlign: "center"}}/>
                    <Column body={kassaView} editor={kassaEditor} header={"Касса"} field="kassa" style={{width: "11%", textAlign: "center"}}/>
                    <Column body={vikupView} editor={vikupEditor} header={"Выкуп"} field="vikup" style={{width: "11%", textAlign: "center"}}/>
                    <Column body={refundView} editor={refundEditor} header={"Возврат"} field="refund" style={{width: "11%", textAlign: "center"}}/>
                    <Column rowEditor={allowEdit} headerStyle={{ width: '3%', minWidth: '7rem' }} bodyStyle={{ textAlign: 'center' }}/>
                </DataTable>
            </div>

            <Dialog style={{ width: '32rem', fontSize: "1.4rem"}} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={null} visible={visible} onHide={() => {if (!visible) return; setVisible(false); }}>
                <div className="create">
                    <div className="create-row">
                        <label htmlFor="order" className="font-bold">
                            Дата
                        </label>
                        <Calendar
                                id="buttondisplay"
                                value={date}
                                onChange={(e) => setDate(e.target.value)}
                                dateFormat="dd MM yy"
                        />
                    </div>

                    <Button onClick={createItem}>Добавить</Button>
                </div>
            </Dialog>
        </div>
    )
}